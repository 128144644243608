import { useEffect, useState } from "react";
import { FaBars, FaTimes, FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const UnauthenticatedNavbar = () => {
  const [isAtTop, setIsAtTop] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY <= 100);
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navClasses = isAtTop
    ? "fixed top-6 left-1/2 transform -translate-x-1/2 px-6 py-4 rounded-full flex items-center justify-between w-9/12 max-w-5xl z-10 transition-all duration-300 bg-sky-950/90 backdrop-blur-md shadow-md"
    : "fixed top-6 left-1/2 transform -translate-x-1/2 backdrop-blur-md rounded-full px-6 py-3 flex items-center justify-between w-9/12 max-w-3xl z-10 transition-all duration-300 bg-sky-950/80";

  const navItemsColorClasses = isAtTop
    ? "text-gray-300 hover:bg-black/10 hover:bg-backdrop-blur-md hover:text-gray-100"
    : "text-gray-200 hover:bg-sky-950/30 hover:text-gray-100 text-normal";

  return (
    <>
      <nav className={`z-50 ${navClasses}`}>
        <div className={`flex items-center`}>
          <a
            className={`font-semibold mr-4 ${
              isAtTop ? "text-xl text-gray-100" : "text-gray-50"
            } tracking-tight`}
            href="/"
          >
            ReadPDF.io
          </a>
        </div>
        <ul className="hidden md:flex space-x-6">
          <li>
            <a
              href="/#features"
              className={`p-2 hover:rounded-full ${navItemsColorClasses}`}
            >
              Features
            </a>
          </li>
          <li>
            <a
              href="/#faq"
              className={`p-2 hover:rounded-full ${navItemsColorClasses}`}
            >
              FAQ
            </a>
          </li>
          <li>
            <a
              href="/#pricing"
              className={`p-2 hover:rounded-full ${navItemsColorClasses}`}
            >
              Pricing
            </a>
          </li>
        </ul>
        <div
          className="hidden md:flex items-center cursor-pointer"
          onClick={() => navigate("/authenticate")}
        >
          <FaUserCircle
            className={`${navItemsColorClasses} ${
              isAtTop ? "text-3xl" : "text-2xl"
            }`}
          />
        </div>
        <div className="md:hidden flex items-center">
          <button
            className={`text-gray-100 text-2xl ml-4 hover:text-gray-200`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle Menu"
          >
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </nav>
      {isMenuOpen && (
        <div
          className="fixed top-0 left-0 w-full h-screen bg-black/60 backdrop-blur-sm z-40 flex flex-col items-center justify-center "
          onClick={() => setIsMenuOpen(false)}
        >
          <ul
            className="flex flex-col space-y-8 text-center"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <li>
              <a
                href="/#features"
                className="text-gray-200 text-2xl p-2 hover:text-white"
                onClick={() => setIsMenuOpen(false)}
              >
                Features
              </a>
            </li>
            <li>
              <a
                href="/#faq"
                className="text-gray-200 text-2xl p-2 hover:text-white"
                onClick={() => setIsMenuOpen(false)}
              >
                FAQ
              </a>
            </li>
            <li>
              <a
                href="/#pricing"
                className="text-gray-200 text-2xl p-2 hover:text-white"
                onClick={() => setIsMenuOpen(false)}
              >
                Pricing
              </a>
            </li>
            <hr />
            <li>
              <a
                href="/authenticate"
                className="text-gray-200 text-2xl p-2 hover:text-white"
                onClick={() => {
                  setIsMenuOpen(false);
                  navigate("/authenticate");
                }}
              >
                Sign In
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default UnauthenticatedNavbar;
