type PricingTryNowButtonComponentProps = {
  children: any;
  mode: string;
  price: string;
  discount?: string;
  extraClass?: string;
};

const PricingTryNowBtnComponent = ({
  children,
  mode,
  price,
  discount,
  extraClass
}: PricingTryNowButtonComponentProps) => {
  const createInput = (name: string, value: string) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    return input;
  };

  const handleSubmit = () => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = `${process.env.REACT_APP_APPLICATION_URL}/session-checkout`;

    const modeInput = createInput("mode", mode);
    const priceTagInput = createInput("priceTag", price);
    const currentPromo = discount ? createInput("reduction", discount) : null;

    form.appendChild(modeInput);
    form.appendChild(priceTagInput);
    currentPromo && form.appendChild(currentPromo);

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <div className="px-6 pt-6 pb-8 sm:p-10 sm:pt-6">
      <button
        onClick={() => handleSubmit()}
        className={`block w-full py-3 px-6 border border-transparent rounded-full text-center font-medium text-white bg-black hover:bg-gray-900 transition duration-300 ease-in-out transform hover:scale-105 ${extraClass}`}
      >
        {children}
      </button>
    </div>
  );
};

export default PricingTryNowBtnComponent;
