import { useEffect, useRef, useState } from "react";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { ActiveSubscriptionTypes } from "../../types";
import Badge, { BadgeSize } from "../badge/badge.component";
import { InteractionIcon } from "../notification/notification.component";

type UploadToggleModelsProps = {
  selectedModel: string;
  changeSelectedModel: (e: string) => void;
  selectedLanguage?: string;
};

const UploadToggleModels = ({
  selectedModel,
  changeSelectedModel,
  selectedLanguage,
}: UploadToggleModelsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState("default");
  const [languageWarning, setLanguageWarning] = useState(false);

  const { subscriptionStatus, loading } = useCombinedAuthHook();

  const [hasActiveSubscription, setHasActiveSubscription] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (["default", "visuals"].includes(selectedModel)) {
      setSelectedOption(selectedModel);
    }
  }, [selectedModel]);

  useEffect(() => {
    if (!selectedLanguage) {
      return;
    }

    if (selectedLanguage !== "en") {
      setLanguageWarning(true);
    } else {
      setLanguageWarning(false);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (loading) return;
    setHasActiveSubscription(
      ActiveSubscriptionTypes.includes(subscriptionStatus)
    );
  }, [subscriptionStatus, loading]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleOptionChange = (option: string) => {
    if (hasActiveSubscription || option === 'default') {
      changeSelectedModel(option);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative inline-block text-left w-full" ref={dropdownRef}>
      <button
        id="dropdownRadioHelperButton"
        onClick={toggleDropdown}
        className="text-black w-full bg-white ring-1 ring-slate-400 hover:bg-slate-200 focus:ring-4 focus:outline-none focus:ring-slate-400 font-normal rounded-lg px-4 py-1.5 text-center inline-flex items-center justify-between shadow"
        type="button"
      >
        <span>
          Extract{" "}
          {selectedOption === "default" ? "text only" : <>text+visuals 🖼️</>}
        </span>
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="#6b7280"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownRadioHelper"
          className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 dark:divide-gray-600 absolute mt-2"
        >
          <ul
            className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownRadioHelperButton"
          >
            <li className="rounded-lg cursor-pointer">
              <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <div className="flex items-center h-5 cursor-pointer">
                  <input
                    id="helper-radio-4"
                    name="helper-radio"
                    type="radio"
                    value="default"
                    defaultChecked={selectedOption === "default"}
                    onClick={() => handleOptionChange("default")}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                </div>
                <div className="ms-2 text-sm">
                  <label
                    htmlFor="helper-radio-4"
                    className="font-medium text-gray-900 dark:text-gray-300"
                  >
                    <div>Text only</div>
                    <p
                      id="helper-radio-text-4"
                      className="text-xs font-normal text-gray-500 dark:text-gray-300"
                    >
                      This model ignores all visual content and would only read
                      you the text. Perfect for case-studies or documentation.
                      It's the fastest way to convert a PDF to a listenable
                      file.
                    </p>
                  </label>
                </div>
              </div>
            </li>
            <li
              className={`cursor-pointer ${
                !hasActiveSubscription
                  ? "border rounded-md border-amber-100 cursor-not-allowed"
                  : ""
              }`}
            >
              <div
                className={`flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 ${
                  !hasActiveSubscription
                    ? "hover:bg-white cursor-not-allowed"
                    : ""
                }`}
              >
                <div
                  className={`flex items-center h-5 ${
                    !hasActiveSubscription
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                >
                  <input
                    id="helper-radio-5"
                    name="helper-radio"
                    type="radio"
                    value="visuals"
                    disabled={!hasActiveSubscription}
                    defaultChecked={selectedOption === "visuals"}
                    onClick={() => handleOptionChange("visuals")}
                    className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 ${
                      !hasActiveSubscription ? "cursor-not-allowed" : ""
                    }`}
                  />
                </div>
                <div className={`ms-2 text-sm`}>
                  <label
                    htmlFor="helper-radio-5"
                    className={`font-medium text-gray-900 dark:text-gray-300 ${
                      !hasActiveSubscription ? "cursor-not-allowed" : ""
                    }`}
                  >
                    <div>
                      Text+Visuals 🖼️{" "}
                      <Badge size={BadgeSize.Small} bgColor="bg-slate-500">
                        Beta
                      </Badge>
                      {languageWarning && (
                        <Badge size={BadgeSize.Small} bgColor="bg-orange-600">
                          In English
                        </Badge>
                      )}
                    </div>
                    <p
                      id="helper-radio-text-5"
                      className="text-xs font-normal text-gray-500 dark:text-gray-300"
                    >
                      This model reads out text, graphics, charts, tables,
                      formulas, etc. Using GPT4. Slower than the text-only
                      model. Currently limited to 4 images/page and describes in
                      English only.
                    </p>
                    {!hasActiveSubscription && (
                      <div className="text-xs border border-amber-100 px-2 py-1 mt-3 rounded-lg text-center bg-amber-50 flex justify-center gap-2 text-amber-600">
                        <span className="mt-0.5">
                          <InteractionIcon intent="Warning" />
                        </span>
                        This feature is only available with a subscription.
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UploadToggleModels;
