import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { subscriptionStatusState } from '../state/subscription-status.state';

interface SubscriptionStatusResponse {
    status: string;
    error?: string;
}

export const useStripeCheckSubscriptionStatus = (sessionId: string) => {
    const [status, setStatus] = useRecoilState(subscriptionStatusState);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_APPLICATION_URL}/subscription-status/${sessionId}`);
                const data: SubscriptionStatusResponse = await response.json();

                if (response.ok) {
                    setStatus(data.status);
                    setError(null);
                } else {
                    setStatus('');
                    setError(data.error || 'Unknown error occurred');
                }
            } catch (err: any) {
                setError(err.message);
                setStatus('');
            } finally {
                setLoading(false);
            }
        };

        if (!sessionId || sessionId === "" || loading === true || status) {
            return;
        }
        
        fetchSubscriptionStatus();
    }, [sessionId]);

    return { 
        status: status,
        isLoading: loading,
        error: error
    };
};