import "./waveform-player.styles.scss";

type WaveformPlayerComponentProps = {
  badge?: string;
  theme?: "dark" | "light";
};

const WaveformPlayerComponent = ({
  badge,
  theme = "light",
}: WaveformPlayerComponentProps) => {
  const isLightTheme = theme === "light";

  const bgColor = isLightTheme ? "bg-white" : "bg-slate-800";
  const waveformFill = isLightTheme ? "fill-slate-500" : "fill-slate-100";

  return (
    <div
      className={`relative rounded-full  h-15 amplitude-player-waveform my-4 pr-2 ${bgColor} ${theme}`}
    >
      {badge && (
        <div className="absolute w-fit py-4 lg:px-2 -top-8 -right-5">
          <div
            className="py-0.5 pl-2 px-1 pb-1 bg-slate-600 items-center text-indigo-100 leading-none rounded-full inline-flex"
            role="alert"
          >
            <span className="font-light mr-2 text-left flex-auto text-xs">
              {badge}
            </span>
          </div>
        </div>
      )}
      <div className="flex size-full items-center gap-2">
        <div
          className={`cursor-pointer amplitude-play-pause w-16 h-16 rounded-full border border-play-pause-light-border shadow-xl flex items-center justify-center ${bgColor}`}
          data-amplitude-song-index="0"
        >
          <svg
            id="play-icon"
            className="ml-[10px]"
            width="31"
            height="37"
            viewBox="0 0 31 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.6901 16.6608L4.00209 0.747111C2.12875 -0.476923 0.599998 0.421814 0.599998 2.75545V33.643C0.599998 35.9728 2.12747 36.8805 4.00209 35.6514L29.6901 19.7402C29.6901 19.7402 30.6043 19.0973 30.6043 18.2012C30.6043 17.3024 29.6901 16.6608 29.6901 16.6608Z"
              className={waveformFill}
            />
          </svg>

          <svg
            id="pause-icon"
            width="24"
            height="36"
            viewBox="0 0 24 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="6"
              height="36"
              rx="3"
              className="fill-slate-500 dark:fill-slate-400"
            />
            <rect
              x="18"
              width="6"
              height="36"
              rx="3"
              className="fill-slate-500 dark:fill-slate-400"
            />
          </svg>
        </div>
        <div className="flex grow flex-col">
          <div className="h-6 w-[180px]">
            <div
              className="amplitude-wave-form -mt-2"
              data-amplitude-song-index="0"
            ></div>
            <input
              type="range"
              className="amplitude-song-slider"
              data-amplitude-song-index="0"
              // id="global-large-song-slider"
              id="song-played-progress-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaveformPlayerComponent;
