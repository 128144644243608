import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { CiCircleInfo } from "react-icons/ci";
import { FaShareAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import PlayerTrackPageComponent from "../../components/player-track-page/player-track-page.component";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { tryGetTrackByUserUidAndFileId } from "../../hooks/database-file";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";
import { ActiveSubscriptionTypes, UserTracks } from "../../types";
import { decodeString } from "../../utils/crypto-utils";

import "./track.styles.scss";

const TrackPage = () => {
  useTitle("Listen");
  const { user, databaseUser, subscriptionStatus } = useCombinedAuthHook();
  const { userId, fileId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [urlCleanUserId, setUrlCleanUserId] = useState(decodeString(userId));
  const [mp3Url] = useState(
    `${process.env.REACT_APP_FE_URL}/track/${userId}/${fileId}`
  );

  const [timestampsData, setTimestampsData] = useState<any[]>([]);
  const [currentTime, setCurrentTime] = useState(0);

  const [trackNotFound, setTrackNotFound] = useState(true);
  const [stateTrack, setTrack] = useState<UserTracks>();
  const [trackStartTime, setTrackStartTime] = useState(0);

  useResetScroll();

  const getCurrentWordIndex = (currentTime: number) => {
    const currentTimeMs = currentTime * 1000;
    for (let i = 0; i < timestampsData.length; i++) {
      if (timestampsData[i].time >= currentTimeMs) {
        return i - 1;
      }
    }
    return timestampsData.length - 1;
  };

  const currentWordIndex = getCurrentWordIndex(currentTime);

  const CHUNK_SIZE = 40;
  const chunkNumber = Math.floor(currentWordIndex / CHUNK_SIZE);

  const wordsToDisplay = useMemo(() => {
    const start = chunkNumber * CHUNK_SIZE;
    const end = Math.min(timestampsData.length - 1, start + CHUNK_SIZE - 1);
    return timestampsData.slice(start, end + 1);
  }, [chunkNumber, timestampsData]);

  useEffect(() => {
    if (userId && fileId) {
      tryGetTrackByUserUidAndFileId(urlCleanUserId, fileId).then(
        (track: UserTracks | null) => {
          if (!track) {
            setTrackNotFound(true);
            return;
          }

          setTrack(track!);
          setTrackNotFound(false);
        }
      );
    }
  }, []);

  const handleTimeUpdate = (time: number) => {
    setCurrentTime(time);
  };

  useEffect(() => {
    if (stateTrack && stateTrack.recordingUrl) {
      const targetSpeechDataEndpoint = stateTrack.recordingUrl.replace(
        ".mp3",
        "_speech_marks.json"
      );
      fetch(targetSpeechDataEndpoint)
        .then((response) => response.json())
        .then((data) => {
          const wordData = data.filter(
            (item: any) =>
              item.type === "word" &&
              !(item.value.includes("<") && item.value.includes(">"))
          );
          setTimestampsData(wordData);
        })
        .catch((_) => {});
    }
  }, [stateTrack]);

  useEffect(() => {
    if (!stateTrack || !stateTrack.timestamps) return;

    const userTimestamp = stateTrack.timestamps[user.uid as any];

    if (!userTimestamp) return;

    setTrackStartTime(userTimestamp.activeTimestamp);
  }, [stateTrack, user.uid]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const isUserTheTrackOwner = urlCleanUserId === user.uid;

  // todo - name edit
  // const handleTrackNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  // setTrackNameState(e.target.value);
  // };

  // const handleTrackNameBlur = () => {
  //   setIsEditing(false);
  //   // Optionally, save the updated track name to the server here
  // };

  const handleShareClick = () => {
    navigator.clipboard.writeText(mp3Url);
    toast.success("URL copied to clipboard!");
  };

  const formatDate = (date?: string) => {
    if (!date) return "";
    const dateFormat = new Date(date);
    return `${dateFormat.toDateString()}, at ${dateFormat.toLocaleTimeString()}`;
  };

  return (
    <>
      {trackNotFound ? (
        <h2>Track not found.</h2>
      ) : (
        <>
          {!ActiveSubscriptionTypes.includes(subscriptionStatus) && (
            <div className="text-center py-4 lg:px-4">
              <div
                className="p-2 bg-blue-200 items-center text-gray-600 leading-none rounded-full flex lg:inline-flex shadow-sm px-5"
                role="alert"
              >
                <div className="relative -top-0.5 mr-3">
                  <CiCircleInfo className="w-4 h-4 mt-1 ml-2" />
                </div>
                <span className="mr-2 text-left flex-auto">
                  You can listen to your PDFs and share them with others now!{" "}
                  <Link className="underline" to="/app/pricing">
                    Try now!
                  </Link>
                </span>
              </div>
            </div>
          )}
          <div className="flex flex-col items-center h-fit md:h-screen sm:p-4">
            <div className="w-full h-[75%] bg-white rounded-lg shadow-md p-2 sm:p-6 md:flex md:flex-col md:justify-between">
              <div className="flex flex-col md:flex-row items-center mb-6 mt-6 gap-4">
                {/* <img
            src={artworkUrl}
            alt="Artwork"
            className="w-32 h-32 rounded-lg mb-4 md:mb-0 md:mr-6"
          /> */}
                <div className="flex-grow">
                  {/* {isEditing ? (
              <input
                type="text"
                value={trackName}
                onChange={handleTrackNameChange}
                onBlur={handleTrackNameBlur}
                className="text-2xl font-semibold border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"
                autoFocus
              />
            ) : ( */}
                  {stateTrack!.model === "visuals" && (
                    <>
                      <p className="text-sm text-gray-500">Text+Visuals 🖼️</p>
                    </>
                  )}
                  <div className="flex items-center">
                    <h2 className="text-2xl font-semibold">
                      {stateTrack!.trackUid}
                    </h2>
                    {/* <button
                      onClick={handleEditClick}
                      className="ml-2 text-gray-600"
                    >
                      <FaPen size={15} />
                    </button> */}
                  </div>
                  {/* )} */}
                  <p className="text-gray-500 text-sm tracking-tight">
                    {formatDate(stateTrack?.date)}
                  </p>
                </div>
                {ActiveSubscriptionTypes.includes(subscriptionStatus) &&
                  isUserTheTrackOwner && (
                    <button
                      onClick={handleShareClick}
                      className="bg-orange-500 hover:bg-orange-600 text-white rounded-xl shadow-xl p-2 pl-4 pr-4"
                    >
                      Share
                      <FaShareAlt
                        className="inline ml-2"
                        color="#fff"
                        size={10}
                      />
                    </button>
                  )}
              </div>
              <div>
                <div className="transcript mt-4">
                  {wordsToDisplay.map((wordData, index) => {
                    const wordIndex = chunkNumber * CHUNK_SIZE + index;
                    const isCurrentWord = wordIndex === currentWordIndex;
                    const isNextWord =
                      wordIndex < currentWordIndex + 4 &&
                      wordIndex > currentWordIndex;
                    const isPreviousWord =
                      wordIndex > currentWordIndex - 4 &&
                      wordIndex < currentWordIndex;
                    return (
                      <span
                        key={wordIndex}
                        className={
                          isCurrentWord
                            ? "highlight"
                            : isPreviousWord || isNextWord
                            ? "light-highlight"
                            : ""
                        }
                      >
                        {wordData.value}{" "}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="w-full flex items-center justify-center mt-16 relative">
                {stateTrack && (
                  <PlayerTrackPageComponent
                    author={urlCleanUserId}
                    trackUid={stateTrack.fileId}
                    url={stateTrack.recordingUrl}
                    startTime={trackStartTime}
                    onTimeUpdate={handleTimeUpdate}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TrackPage;
