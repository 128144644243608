import { child, get, getDatabase, ref, update } from "firebase/database";
import { UserTracks } from "../types";

const db = getDatabase();

const getUserTracks = async (userUid: string) => {
  const dbRef = ref(db);
  try {
    const snapshot = await get(child(dbRef, `user-tracks/${userUid}`));
    if (snapshot.exists()) {
      return snapshot.val();
    }
  } catch (e) {}

  return null;
};

export const getForUser = async (userUid: string) => {
  if (!userUid) {
    throw new Error("Can't fetch tracks without the required parameters.");
  }

  return getUserTracks(userUid);
};

export const tryGetTrackByUserUidAndFileId = async (
  userUid: string,
  fileId: string
): Promise<UserTracks | null> => {
  const dbRef = ref(db);
  try {
    const snapshot = await get(child(dbRef, `user-tracks/${userUid}`));
    if (snapshot.exists()) {
      const snapshotVal = snapshot.val();
      return snapshotVal.find((x: UserTracks) => x.fileId === fileId);
    }
  } catch (e) {}

  return null;
};

const tryGetTrackIndexByUserUidAndFileId = async (
  userUid: string,
  fileId: string
): Promise<number | null> => {
  const dbRef = ref(db);
  try {
    const snapshot = await get(child(dbRef, `user-tracks/${userUid}`));
    if (snapshot.exists()) {
      const snapshotVal = snapshot.val();
      return snapshotVal.findIndex((x: UserTracks) => x.fileId === fileId);
    }
  } catch (e) {}

  return null;
};

export const tryUpdateTimestamp = async (
  userUid: string,
  author: string,
  trackId: string,
  activeTimestamp: string
) => {
  try {
    const index = await tryGetTrackIndexByUserUidAndFileId(author, trackId);

    if (!index || index < 0) throw new Error();

    await update(
      ref(db, `user-tracks/${author}/${index}/timestamps/${userUid}`),
      { activeTimestamp }
    );
  } catch (e) {
    console.error("Error updating timestamp:", e);
  }
};
