import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle,
} from "react-icons/fa";

export type NotificationType = {
  Error: "Error";
  Warning: "Warning";
  Success: "Success";
  Info: "Info";
};

export type NotificationProps = {
  message: string;
  type: keyof NotificationType;
};

type InteractionIconProps = {
  intent: keyof NotificationType;
};

const colorCode = new Map<keyof NotificationType, string>();

colorCode.set("Error", "bg-red-100 border-red-400 text-red-700");
colorCode.set("Info", "bg-sky-100 border-blue-400 text-blue-700");
colorCode.set("Success", "bg-teal-100 border-teal-500 text-teal-900");
colorCode.set("Warning", "bg-yellow-100 border-yellow-400 text-yellow-700");

export const InteractionIcon = ({ intent }: InteractionIconProps) => {
  switch (intent) {
    case "Error":
      return <FaExclamationCircle />;
    case "Success":
      return <FaCheckCircle />;
    case "Warning":
      return <FaExclamationTriangle />;
    default:
      return <FaInfoCircle />;
  }
};

const Notification = ({ message, type }: NotificationProps) => {
  return (
    <div
      role="alert"
      className={`border-t-4 rounded-bpx-4 rounded-sm py-3 shadow-md w-full mb-3 ${colorCode.get(
        type
      )}`}
    >
      <div className="flex px-2">
        <span className="mt-1.5">
          <InteractionIcon intent={type} />
        </span>
        <div className="ml-4">
          <h3 className="font-semibold">{type}</h3>
          <p className="text-sm">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default Notification;
