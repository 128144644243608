
type PricingSectionProps = {
  children: any;
};

const PricingSectionComponent = ({ children }: PricingSectionProps) => {
  return (
    <div className="bg-white shadow-xl overflow-hidden sm:rounded-lg relative">
      {children}
    </div>
  );
};

export default PricingSectionComponent;
