export enum BadgeSize {
  Small = "small",
  Normal = "normal",
}

export type BadgeProps = {
  children: string;
  size?: BadgeSize;
  color?: string;
  bgColor?: string;
};

const Badge = ({ children, size, color, bgColor }: BadgeProps) => {
  function getPadding() {
    if (size && size === BadgeSize.Small) {
      return "px-2 py-0.5";
    }
    return "px-2 py-1";
  }

  return (
    <span
      className={`ml-2 ${getPadding()} text-xs font-semibold ${
        !!color ? color : "text-white"
      } ${bgColor ? bgColor : "bg-blue-500"} rounded-full`}
    >
      {children}
    </span>
  );
};

export default Badge;
