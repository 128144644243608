import { Link, Navigate } from "react-router-dom";
import useStripeSession from "../../hooks/stripe-session.hook";
import { useDatabaseUser } from "../../hooks/database-user.hook";
import { FaCheckCircle } from "react-icons/fa";

const ReturnPage = () => {
  const { status, sessionId } = useStripeSession();
  const { updateUser } = useDatabaseUser();

  if (status === "open") {
    updateUser({ stripeSessionId: "" });
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    updateUser({ stripeSessionId: sessionId });
    return (
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-center mx-auto">
        <FaCheckCircle size={50} className="mx-auto text-green-600" />
        <h2 className="text-2xl font-bold text-gray-800 mt-4">
          Welcome to the club!
        </h2>
        <p className="text-gray-600 mt-2">
          Thank you for your subscription! It was successfully activated.
        </p>
        <p className="text-gray-600 mt-2">
          You can now continue using our services. In case of questions, reach
          out through our chatbot, our official channels or email.
        </p>
        <Link
          to={"/app/upload"}
          className="mt-4 inline-block bg-black text-white py-2 px-4 rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-75"
        >
          Upload Now
        </Link>
      </div>
    );
  }

  return null;
};

export default ReturnPage;
