import { useEffect, useState } from "react";
import { FaArrowRight, FaChevronLeft, FaEnvelope } from "react-icons/fa";
import "./success-checkout.styles.scss";
import { useTitle } from "../../hooks/useTitle.hook";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { auth, googleProvider } from "../../firebase";
import { useSetRecoilState } from "recoil";
import { authState } from "../../state/auth.state";
import { Link, useNavigate } from "react-router-dom";
import { mapAuthCodeToMessage } from "../../utils/auth-error-codes-map";
import toast from "react-hot-toast";
import { useDatabaseUser } from "../../hooks/database-user.hook";

const SuccessCheckoutPage = () => {
  const setAuthUser = useSetRecoilState(authState);
  const navigate = useNavigate();
  const { updateUser } = useDatabaseUser();
  useTitle("Create Account");
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [strSessionId, setStrSessionId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get("sid");
    if (!sessionId) {
      navigate("/");
      return;
    }

    setStrSessionId(sessionId);

    try {
      fetch(
        `${process.env.REACT_APP_APPLICATION_URL}/validate-session?session_id=${sessionId}`
      )
        .then((response) => response.json())
        .then((res) => {
          if (!res?.legal) {
            navigate("/");
          }
        });

      fetch(
        `${process.env.REACT_APP_APPLICATION_URL}/get-session?session_id=${sessionId}`
      )
        .then((response) => response.json())
        .then((session) => {
          setFormData((prevData) => ({
            ...prevData,
            email: session.email ?? "",
          }));
          setFormData((prevData) => ({
            ...prevData,
            name: session.name ?? "",
          }));
        });
    } catch (e) {
      toast.error(
        "There was a problem while we were trying to fetch your information. Please reload."
      );
    }
  }, []);

  const handleGoogleSignIn = async () => {
    let loadingToastId;
    try {
      const response = await signInWithPopup(auth, googleProvider);

      loadingToastId = toast.loading("We're almost there...");

      setAuthUser({
        uid: response.user.uid,
        email: response.user.email!,
        name: response.user.displayName ?? "",
        photo: response.user.photoURL ?? "",
      });

      await updateUser(
        {
          name: formData.name,
          email: response.user.email!,
          photo: response.user.photoURL ?? "",
          stripeSessionId: strSessionId,
        },
        response.user.uid
      );

      toast.success("Your account was successfully created!", {
        id: loadingToastId,
      });

      setTimeout(() => {
        navigate("/app");
      }, 500);
    } catch (error: any) {
      toast.error(mapAuthCodeToMessage(error.code), { id: loadingToastId });
    }
  };

  const handleinputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleEmailAuth = async (e: any) => {
    e.preventDefault();
    let loadingToastId;
    try {
      let user;

      loadingToastId = toast.loading("We're almost there...");
      const response = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      user = response.user;
      await updateProfile(user, { displayName: formData.name });

      await updateUser(
        {
          name: formData.name,
          email: user.email!,
          photo: user.photoURL ?? "",
          stripeSessionId: strSessionId,
        },
        user.uid
      );

      setAuthUser({
        uid: user.uid,
        email: user.email!,
        name: user.displayName ?? "",
        photo: user.photoURL ?? "",
      });

      toast.success("Your account was successfully created!", {
        id: loadingToastId,
      });

      setTimeout(() => {
        navigate("/app");
      }, 500);
    } catch (error: any) {
      toast.error(mapAuthCodeToMessage(error.code), { id: loadingToastId });
    }
  };

  const isStepValid = () => {
    switch (step) {
      case 1:
        return formData.name.length > 0;
      case 2:
        return formData.email.length > 0 && formData.email.includes("@");
      case 3:
        return formData.password.length >= 8;
      default:
        return false;
    }
  };

  return (
    <div className="pattern relative h-screen flex justify-center items-center bg-slate-200">
      <div className="max-w-4xl mx-auto bg-slate-700 rounded-lg shadow-xl p-8 mt-20">
        <h1 className="text-4xl font-bold mb-6 text-center text-slate-100">
          Thank you for your purchase!
        </h1>
        <p className="text-xl mb-8 text-center text-slate-300">
          Before moving on, let's first create your account.
        </p>
        <div className="mb-8">
          <div className="flex justify-between items-center">
            {[1, 2, 3].map((i) => (
              <div
                key={i}
                className={`w-1/3 h-2 rounded-full ${
                  i <= step ? "bg-green-500" : "bg-slate-500"
                }`}
              />
            ))}
          </div>
        </div>
        <form onSubmit={handleEmailAuth}>
          {step === 1 && (
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-slate-100">
                What's your name?
              </h2>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleinputChange}
                placeholder="Enter your full name"
                className="w-full bg-slate-600 text-slate-100 placeholder-slate-400 rounded-lg"
              />
            </div>
          )}
          {step === 2 && (
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-slate-100">
                What's your email?
              </h2>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleinputChange}
                placeholder="Enter your email address"
                className="w-full bg-slate-600 text-slate-100 placeholder-slate-400 rounded-lg"
              />
            </div>
          )}
          {step === 3 && (
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-slate-100">
                Create a password
              </h2>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleinputChange}
                placeholder="Enter a strong password"
                className="w-full bg-slate-600 text-slate-100 placeholder-slate-400 rounded-lg"
              />
              <p className="text-sm text-slate-400">
                Password must be at least 8 characters long
              </p>
            </div>
          )}
          <div className="mt-8 flex justify-between items-center">
            {step > 1 && (
              <button
                type="button"
                onClick={handlePrevStep}
                // variant="outline"
                className="flex items-center text-slate-300 pointer"
              >
                <FaChevronLeft className="mr-2 h-4 w-4" /> Back
              </button>
            )}
            {step < 3 ? (
              <button
                type="button"
                onClick={handleNextStep}
                disabled={!isStepValid()}
                className="ml-auto bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
              >
                Next <FaArrowRight className="ml-2 w-5 h-5" />
              </button>
            ) : (
              <button
                type="submit"
                disabled={!isStepValid()}
                className="ml-auto bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              >
                Create Account
              </button>
            )}
          </div>
        </form>
        <div className="mt-8">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-slate-500"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-slate-700 text-slate-400">
                Or continue with
              </span>
            </div>
          </div>
          <div className="mt-6">
            <button
              type="button"
              onClick={() => handleGoogleSignIn()}
              className="w-full bg-slate-600 hover:bg-slate-500 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out flex items-center justify-center"
            >
              <FaEnvelope className="mr-2 h-5 w-5" />
              Sign up with Google
            </button>
          </div>
        </div>
        <div className="mt-2 text-center">
          <span className="text-sm px-2 text-slate-400">
            <Link to={"/terms-and-conditions"} target="_blank" className="border-b border-dashed border-slate-400 hover:border-solid">
              T&C
            </Link>{" "}
            apply.
          </span>
        </div>
      </div>
    </div>
  );
};

export default SuccessCheckoutPage;
