import * as Amplitude from "amplitudejs";
import { useEffect, useState } from "react";
import { FaMicrophoneAlt, FaUserFriends } from "react-icons/fa";
import { FaChartSimple, FaLanguage } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Badge from "../../components/badge/badge.component";
import PdfPlayerComponent from "../../components/pdf-player/pdf-player.component";
import WaveformPlayerComponent from "../../components/waveform-player/waveform-player.component";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import useCurrency from "../../hooks/currency.hook";
import { useTitle } from "../../hooks/useTitle.hook";
import { authState } from "../../state/auth.state";
import {
  navigateToAuthModalProp,
  showAuthModal,
} from "../../state/showAuthModal.state";

import FAQSection from "../../components/faq/faq.component";
import PricingSectionComponent from "../../components/pricing-section/pricing-section.component";
import PricingFeaturesListComponent, {
  PricingFeatureComponent,
} from "../../components/pricing-section/pricing.features-list.component";
import PricingTryNowBtnComponent from "../../components/pricing-section/pricing.try-now-btn.component";
import RatingSection from "../../components/rating/rating.component";
import "./home.styles.scss";

const HomePage = () => {
  useTitle();
  const authUser = useRecoilValue(authState);
  const setShowModal = useSetRecoilState(showAuthModal);
  const setNavigateToGlobalProp = useSetRecoilState(navigateToAuthModalProp);
  const navigate = useNavigate();
  const { user } = useCombinedAuthHook();
  const currency = useCurrency();

  const [isYearly, setIsYearly] = useState(false);

  configureAmplifyPlayer();

  function configureAmplifyPlayer() {
    Amplitude.init({
      songs: [
        {
          name: "Biology",
          artist: "ReadPDF.io",
          url: "/sample.mp3",
        },
        {
          name: "Agricultural Analysis",
          artist: "ReadPDF.io",
          url: "/agricultural_analysis.mp3",
        },
        {
          name: "DNA",
          artist: "ReadPDF.io",
          url: "/dna_sample.mp3",
        },
        {
          name: "Flamenco",
          artist: "ReadPDF.io",
          url: "/flamenco.mp3",
        },
      ],
      waveforms: {
        sample_rate: "100",
      },
    });
  }

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  useEffect(() => {
    if (user) {
      navigate("/app");
    }
  }, [user, navigate]);

  const activePrice = (): {
    monthly: string;
    yearly: string;
    original: string;
  } => {
    switch (currency) {
      case "USD":
        return {
          monthly: "$8.99",
          yearly: "$53.99",
          original: "$89.99",
        };
      default:
        return {
          monthly: "7.99€",
          yearly: "51.59€",
          original: "85.99€",
        };
    }
  };

  return (
    <>
      <section
        className={`relative overflow-hidden w-full h-screen rounded-b-3xl bg-gradient-to-br from-slate-400 via-slate-200 to-slate-50 shadow-sm-light`}
      >
        <div className="book-bg hidden md:block absolute top-0 bottom-0 left-0 right-0"></div>
        <div className="relative h-full flex items-center justify-center">
          <div className="absolute top-1/4 left-0 w-full h-full flex flex-col items-center">
            <h1 className="mb-2 text-center text-5xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              ReadPDF.io
            </h1>
            <h2 className="mb-8 text-center text-3xl tracking-tight font-bold text-gray-900 dark:text-white">
              Upload your PDFs and listen to them
              <br />
              anytime, anywhere now!
            </h2>
            <WaveformPlayerComponent badge="gastrointestinal_tract-v3.pdf" />
            <div>
              <button
                onClick={() => {
                  setNavigateToGlobalProp(
                    // "/app/subscribe?p=1PcwLAFJ3gpTYLFuPDyJnyC4"
                    "/app/pricing"
                  );
                  setShowModal(true);
                }}
                className="bg-black text-white font-semibold px-6 py-3 rounded-full hover:bg-gray-800 transition mr-2"
              >
                Try for Free!
              </button>

              <a
                href="#samples"
                className="bg-white text-gray-800 outline outline-1 outline-offset-1 outline-gray-200 font-semibold px-4 py-3 rounded-full hover:bg-black hover:text-white transition"
              >
                Hear Some More 🎧
              </a>
            </div>
            <RatingSection />
          </div>
        </div>
      </section>

      <section className="bg-body rounded-xl mt-6" id="features">
        <div className="bg-gray-50 font-sans leading-normal tracking-normal">
          <div className="container mx-auto px-4 py-16">
            <h1 className="mb-2 text-center text-5xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Listen to Your PDFs
            </h1>
            <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
              Because you know - ReadPDF.io reads PDFs for you 😉
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition duration-500 hover:scale-105">
                <div className="flex items-center justify-center h-20 w-20 gradient-bg text-slate-900 rounded-full mx-auto mb-6 feature-icon">
                  <FaChartSimple size={55} />
                </div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  Listen to your Charts{" "}
                  <span className="relative -mt-1">
                    <Badge bgColor="bg-orange-500">AI 🔮</Badge>
                  </span>
                </h2>
                <p className="text-gray-600">
                  Hear the visual information as it is on the page, without
                  having to open your phone and look it up!
                </p>
              </div>
              <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition duration-500 hover:scale-105">
                <div className="flex items-center justify-center h-20 w-20 gradient-bg text-slate-900 rounded-full mx-auto mb-6 feature-icon">
                  <FaMicrophoneAlt size={55} />
                </div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  Realistic Voice
                </h2>
                <p className="text-gray-600">
                  Clear, natural and non-irritating voice to help you get to the
                  point.
                </p>
              </div>
              <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition duration-500 hover:scale-105">
                <div className="flex items-center justify-center h-20 w-20 gradient-bg text-slate-900 rounded-full mx-auto mb-6 feature-icon">
                  <FaLanguage size={55} />
                </div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  Many Languages
                </h2>
                <p className="text-gray-600">
                  Listen to your PDFs in English, Spanish, Portuguese, German
                  and more...
                </p>
              </div>
              <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition duration-500 hover:scale-105">
                <div className="flex items-center justify-center h-20 w-20 gradient-bg text-slate-900 rounded-full mx-auto mb-6 feature-icon">
                  <FaUserFriends size={55} />
                </div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  Share Your File
                </h2>
                <p className="text-gray-600">
                  With your friends who don't have a subscription.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <section className="bg-body rounded-xl mt-20 mb-40">
          <div className="w-full lg:w-1/2 p-4 mx-auto relative">
            <div className="absolute -bottom-10 left-20 w-10/12 h-96 bg-orange-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
            <div className="absolute -top-10 left-20 w-10/12 h-96 bg-orange-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
            <div className="relative overflow-hidden rounded-lg">
              <video controls src="/readpdfio-video-2.mp4" itemType="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </section> */}
        <div className="flex items-center justify-center" id="samples">
          <div className="relative py-16 pb-20 rounded-3xl">
            <div className="absolute bottom-0 left-20 w-2/3 h-72 bg-orange-50 rounded-full mix-blend-multiply filter blur-xl opacity-50 animate-blob animation-delay-4000"></div>
            <div className="absolute top-0 left-20 w-2/3 h-72 bg-orange-50 rounded-full mix-blend-multiply filter blur-xl opacity-50 animate-blob animation-delay-4000"></div>
            <h2 className="mb-8 text-center text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Hear me out 👇
            </h2>
            <div className="flex flex-col md:flex-row gap-24 items-center justify-center">
              <PdfPlayerComponent
                pdfName="agriculture-lecture-2.pdf"
                songIndex={1}
              >
                <p className="mt-4 text-gray-700 font-serif">
                  Agricultural analysis involves the{" "}
                  <span className="bg-yellow-100">
                    evaluation of soil, water, crops, and farming practices
                  </span>{" "}
                  to optimize agricultural productivity and sustainability. It
                  includes soil testing for nutrient levels, pH, and moisture
                  content to determine the best crop choices and fertilization
                  methods. Crop analysis monitors{" "}
                  <span className="bg-yellow-100">
                    plant health, growth patterns, and yields
                  </span>
                  , while water analysis assesses quality and irrigation
                  efficiency. Additionally, agricultural analysis examines the
                  impact of farming practices on the environment, helping
                  farmers{" "}
                  <span className="bg-yellow-100">
                    {" "}
                    make informed decisions to enhance crop production, reduce
                    waste, and promote sustainable practices
                  </span>
                  . This scientific approach is important for improving food
                  security and supporting sustainable agriculture.
                </p>
              </PdfPlayerComponent>
              <PdfPlayerComponent pdfName="DNA_RNA.pdf" songIndex={2}>
                <img src={"sample-2-pdf-image.png"} alt="DNA structures" />
                <p className="mt-4 text-gray-800">
                  DNA (Deoxyribonucleic acid) is the molecule that carries
                  genetic information in living organisms, encoding instructions
                  for the development, functioning, and reproduction of cells.
                  RNA (Ribonucleic acid) is a single-stranded molecule that
                  plays a critical role in translating DNA’s genetic code into
                  proteins. RNA also has functions in gene regulation and is
                  essential in processes like transcription and translation.{" "}
                  <br />
                </p>
              </PdfPlayerComponent>
              <PdfPlayerComponent
                pdfName="Flamenco.pdf "
                songIndex={3}
                trailingBadge="Spanish"
              >
                <p className="mt-4 text-gray-700 font-serif">
                  La flamenco es una expresión artística profundamente arraigada
                  en la cultura española, especialmente en las regiones de
                  Andalucía, Extremadura y Murcia. Esta tradición, que combina
                  música, canto y danza, se ha transmitido de generación en
                  generación, convirtiéndose en un símbolo de la identidad
                  española.
                </p>
                <p className="mt-4 text-gray-700 font-serif">
                  El flamenco se caracteriza por su intensidad emocional y la
                  complejidad de sus ritmos. Los elementos principales del
                  flamenco incluyen el "cante" (canto), el "toque" (guitarra) y
                  el "baile" (danza). Cada uno de estos componentes se une para
                  crear una experiencia artística única que refleja las
                  alegrías, penas y pasiones de la vida.
                </p>
                <p className="mt-4 text-gray-700 font-serif">
                  El flamenco ha evolucionado con el tiempo, incorporando
                  influencias de otras culturas, pero siempre manteniendo su
                  esencia tradicional. Hoy en día, el flamenco es reconocido
                  como Patrimonio Cultural Inmaterial de la Humanidad por la
                  UNESCO, y continúa siendo una parte vital de la cultura y el
                  arte de España.{" "}
                </p>
              </PdfPlayerComponent>
            </div>
          </div>
        </div>
      </section>

      <section
        className="faq-pattern bg-white dark:bg-gray-900 rounded-xl mt-10 shadow"
        id="faq"
      >
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <h2 className="mb-8 text-center text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Frequently asked questions
          </h2>
          {FAQSection()}
        </div>
      </section>

      {!authUser && (
        <>
          <section
            id="pricing"
            className="flex flex-col md:flex-row rounded-xl mt-10 pb-10 overflow-hidden"
          >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center">
                <h2 className="mb-8 text-center text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  Pricing
                </h2>
              </div>
              <div className="mt-16 flex flex-col md:flex-row gap-8">
                <PricingSectionComponent>
                  <div className="px-6 py-8 sm:p-10 sm:pb-6">
                    <div>
                      <h3 className="inline-flex px-4 py-1 rounded-full text-xs font-semibold tracking-wide uppercase border outline-2 border-orange-700 text-orange-700">
                        Pay Monthly
                      </h3>
                    </div>
                    <div className="mt-1 flex items-baseline text-4xl font-extrabold">
                      {activePrice().monthly}/mo
                    </div>
                    <p className="mt-2 text-xl text-gray-700">
                      Paid monthly, from Day 2 on
                    </p>
                    <PricingFeaturesListComponent>
                      <PricingFeatureComponent>
                        Try for free
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        Unlimited PDF uploads
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        High-quality MP3 conversion
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        Share converted files
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        <>
                          Text{" "}
                          <span className="bg-yellow-100 text-black">
                            highlighting
                          </span>
                        </>
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        AI Description of images and charts 💥
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        Cancel anytime
                      </PricingFeatureComponent>
                    </PricingFeaturesListComponent>
                  </div>
                  <PricingTryNowBtnComponent
                    mode="sub"
                    price="price_1QRfO5FJ3gpTYLFurNywk6Fw"
                  >
                    Try now for Free!
                  </PricingTryNowBtnComponent>
                </PricingSectionComponent>
                <PricingSectionComponent>
                  <div className="px-6 py-8 sm:p-10 sm:pb-6">
                    <div>
                      <h3 className="inline-flex px-4 py-1 rounded-full text-xs font-semibold tracking-wide uppercase bg-gradient-to-r from-yellow-300 to-orange-400 text-yellow-900">
                        One Time Payment
                      </h3>
                      <h3 className="inline-flex px-4 py-1 ml-2 rounded-full text-xs font-semibold tracking-wide uppercase bg-red-400 text-yellow-900">
                        -40% sale
                      </h3>
                    </div>
                    <div className="mt-1 flex gap-1 items-baseline text-4xl font-extrabold">
                      <div>
                        <span className="text-lg font-semibold">
                          <s>{activePrice().original}</s>
                        </span>
                      </div>
                      {activePrice().yearly}
                    </div>
                    <p className="mt-0.5 text-xs text-gray-400 tracking-tight lowercase">
                      With code{" "}
                      <span className="uppercase">
                        <strong>40SALE</strong>
                      </span>{" "}
                      on checkout
                    </p>
                    <p className="mt-2 text-xl text-gray-700">Paid once</p>
                    <PricingFeaturesListComponent>
                      <PricingFeatureComponent>
                        Try for free
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        Unlimited PDF uploads
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        High-quality MP3 conversion
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        Share converted files
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        <>
                          Text{" "}
                          <span className="bg-yellow-100 text-black">
                            highlighting
                          </span>
                        </>
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        AI Description of images and charts 💥
                      </PricingFeatureComponent>
                      <PricingFeatureComponent>
                        You get all future updates
                      </PricingFeatureComponent>
                    </PricingFeaturesListComponent>
                  </div>
                  <PricingTryNowBtnComponent
                    mode="one"
                    price="price_1QRfK3FJ3gpTYLFuc4pNDqTI"
                    discount="58SALE"
                  >
                    Get Now!
                  </PricingTryNowBtnComponent>
                </PricingSectionComponent>
              </div>
            </div>
          </section>

          <section className="webb-pattern-bg rounded-lg py-12 bg-sky-700 text-white text-center mb-6 w-[97%] mx-auto">
            <div className="container mx-auto px-6 max-w-screen-xl">
              <h2 className="text-3xl font-extrabold mb-4">
                Get Started with ReadPDF.io
              </h2>
              <p className="mb-6">
                Transform the way you study! Sign up today and start converting
                your PDFs into high-quality audio.
              </p>
              <Link
                to="/authenticate"
                className="bg-white text-blue-500 font-semibold py-2 px-4 rounded-full hover:bg-gray-100 cursor-pointer"
              >
                Sign Up Now
              </Link>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default HomePage;
