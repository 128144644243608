export const mapAuthCodeToMessage = (authCode: string): string => {
  switch (authCode) {
    case "auth/invalid-password":
      return "The password is invalid.";
    case "auth/invalid-email":
      return "The email is invalid.";
    case "auth/invalid-credential":
      return "The email/password combination is wrong.";
    case "auth/email-already-exists":
      return "This email already exists.";
    case "auth/user-not-found":
      return "No user found with this email.";
    case "auth/too-many-requests":
      return "The account was temporarily locked due to too many failed attempts. Please, try again later.";
    default:
      return "An error occurred while logging you in. Please try again.";
  }

};