import { useEffect, useState } from "react";
import { FaPlayCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { getForUser } from "../../hooks/database-file";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { authState } from "../../state/auth.state";
import { UserTracks } from "../../types";
import { encodeString } from "../../utils/crypto-utils";
import { useTitle } from "../../hooks/useTitle.hook";
import NewPlaceholderTrackComponent from "../../components/new-placeholder-track/new-placeholder-track.component";

const DashboardPage = () => {
  useTitle("My Library");
  const authUser = useRecoilValue(authState);
  const [stateTracks, setTracks] = useState([] as UserTracks[]);
  const navigate = useNavigate();
  useResetScroll();
  useCombinedAuthHook();

  const playerColor = [
    "bg-slate-500",
    "bg-slate-600",
    "bg-sky-900",
    "bg-slate-400",
    "bg-slate-600",
    "bg-sky-800",
    "bg-slate-700",
    "bg-teal-950",
    "bg-indigo-900",
    "bg-teal-800",
  ];

  useEffect(() => {
    if (!authUser) return;

    getForUser(authUser.uid).then((tracks: UserTracks[] | null) => {
      if (!tracks) return;

      setTracks(tracks);
    });
  }, [authUser]);

  const toReadableDate = (date?: string) => {
    return date ? new Date(date).toDateString() : "";
  };

  const generatePlayerBg = (track: string, index: number) => {
    return !track ? playerColor[index % playerColor.length] : ``;
  };

  return (
    <>
      <div className="mb-9">
        <h1 className="bold text-center text-xl">My Library</h1>
      </div>
      
      {!stateTracks ||
        (stateTracks.length === 0 && <NewPlaceholderTrackComponent />)}

      {stateTracks.length > 0 && (
        <>
          <div className="grid grid-cols-[repeat(auto-fit,12.5rem)] justify-center gap-3">
            {[...stateTracks].reverse().map((track, idx) => (
              <div
                className="flex flex-col bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer mx-3 mb-4 w-50 h-72 overflow-hidden"
                onClick={() =>
                  navigate(
                    `/app/track/${encodeString(authUser.uid)}/${track.fileId}`
                  )
                }
                key={idx}
              >
                <div className="relative overflow-hidden w-full h-full">
                  <div
                    className={`relative w-full h-full object-cover rounded-t-lg truncate ${generatePlayerBg(
                      track.coverPage,
                      idx
                    )}`}
                  >
                    {track.coverPage && (
                      <img
                        src={`data:image/png;base64,${track.coverPage}`}
                        className="w-full h-full"
                        alt="Cover"
                      />
                    )}
                  </div>
                  <div className="absolute bottom-0 left-0 bg-gradient-to-t from-slate-700 from-0% via-[#33415530] via-85% to-transparent w-full p-4 rounded-t-lg">
                    <h3 className="text-lg font-semibold text-white">
                      <FaPlayCircle className="inline -mt-0.5" />{" "}
                      {track.trackUid}
                    </h3>
                  </div>
                </div>
                <div className="flex justify-between px-2 py-3 h-fit bg-white">
                  <p className="text-sm text-gray-600 truncate">
                    {toReadableDate(track.date)}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {track.model === "visuals" ? <>🖼️</> : null}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default DashboardPage;
