import { useEffect, useState } from "react";
import { AvailableCurrencies } from "../types";

const euroCountries = new Set([
  "Austria",
  "Belgium",
  "Cyprus",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Portugal",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Andorra",
  "Kosovo",
  "Monaco",
  "Montenegro",
  "San Marino",
  "Vatican City",
  "Albania",
  "Armenia",
  "Azerbaijan",
  "Belarus",
  "Bosnia and Herzegovina",
  "Georgia",
  "Iceland",
  "Kazakhstan",
  "Moldova",
  "North Macedonia",
  "Russia",
  "Serbia",
  "Turkey",
  "Ukraine",
  "Bulgaria",
  "Romania",
  "Croatia",
  "Hungary",
  "Czech Republic",
  "Poland",
  "Sweden",
  "Norway",
]);

const useCurrency = () => {
  const [currency, setCurrency] = useState<AvailableCurrencies>("EUR");

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        if (!response.ok) {
          throw new Error("Failed to fetch location");
        }
        const data = await response.json();
        const country = data.country_name;

        if (euroCountries.has(country)) {
          setCurrency("EUR");
        } else {
          setCurrency("USD");
        }
      } catch (error) {}
    };

    fetchLocation();
  }, []);

  return currency;
};

export default useCurrency;
