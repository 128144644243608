const RatingSection = () => {
  const users = [
    { 
      image: '/Hristo.jpeg',
      alt: 'Hristo'
    },
    { 
      image: '/Martin.jpeg',
      alt: 'Martin'
    },
    { 
      image: '/Sveto.jpeg',
      alt: 'Sveto'
    },
    { 
      image: '/Nicole.jpeg',
      alt: 'Nicole'
    },
  ]

  return (
    <div className="flex items-center space-x-4 p-4 mt-2">
      <div className="flex -space-x-3" aria-label="User avatars">
        {users.map((user, idx) => (
          <div
            key={idx}
            className="relative w-10 h-10 rounded-full border-2 border-slate-200 overflow-hidden"
          >
            <img
              src={user.image}
              alt={`User ${user.alt}`}
              width={40}
              height={40}
              className="object-cover"
            />
          </div>
        ))}
      </div>
      <p className="text-sm font-medium text-gray-900">
        <strong>Helps 20+ people daily</strong><br />
        with their work and studies.
      </p>
    </div>
  );
};

export default RatingSection;
