import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { auth, googleProvider } from "../../firebase";
import { useDatabaseUser } from "../../hooks/database-user.hook";
import { authState } from "../../state/auth.state";
import Notification from "../notification/notification.component";

type AuthModalProps = {
  show: boolean;
  onClose: () => void;
  navigateTo?: string;
};

const AuthModal = ({ show, onClose, navigateTo }: AuthModalProps) => {
  const setAuthUser = useSetRecoilState(authState);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { updateUser } = useDatabaseUser();
  const navigate = useNavigate();

  useEffect(() => {
    return () => setErrorMessage("");
  }, []);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (show) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [show, onClose]);

  const handleAuthSuccess = (user: any) => {
    setAuthUser({
      uid: user.uid,
      email: user.email!,
      name: user.displayName ?? "",
      photo: user.photoURL ?? "",
    });
    if (navigateTo && navigateTo !== "") {
      navigate(navigateTo);
    } else {
      navigate("/app");
    }
    onClose();
  };

  const handleGoogleSignIn = async () => {
    try {
      const response = await signInWithPopup(auth, googleProvider);
      setAuthUser({
        uid: response.user.uid,
        email: response.user.email!,
        name: response.user.displayName ?? "",
        photo: response.user.photoURL ?? "",
      });
      handleAuthSuccess(response.user);
    } catch (error: any) {
      setErrorMessage(mapAuthCodeToMessage(error.code));
      //   console.error("Google sign-in error:", error);
    }
  };

  const handleEmailAuth = async (e: any) => {
    e.preventDefault();
    try {
      let user;

      if (isSignUp) {
        const response = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        user = response.user;
        await updateProfile(user, { displayName: name });
        await updateUser(
          {
            name: name,
            email: user.email!,
            photo: user.photoURL ?? "",
            stripeSessionId: "",
          },
          user.uid
        );
      } else {
        const response = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        user = response.user;
      }

      setAuthUser({
        uid: user.uid,
        email: user.email!,
        name: user.displayName ?? "",
        photo: user.photoURL ?? "",
      });

      handleAuthSuccess(user);
    } catch (error: any) {
      setErrorMessage(mapAuthCodeToMessage(error.code));
      //   console.error("Email authentication error:", error);
    }
  };

  if (!show) {
    return null;
  }

  const mapAuthCodeToMessage = (authCode: string): string => {
    switch (authCode) {
      case "auth/invalid-password":
        return "The password is invalid.";
      case "auth/invalid-email":
        return "The email is invalid.";
      case "auth/invalid-credential":
        return "The email/password combination is wrong.";
      case "auth/email-already-exists":
        return "This email already exists.";
      case "auth/user-not-found":
        return "User not found.";
      case "auth/too-many-requests":
        return "The account was temporarily locked due to too many failed attempts. Please, try again later.";
      default:
        return "";
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold mb-4">
            {isSignUp ? "Sign Up" : "Sign In"}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-800 h-fit"
          >
            &times;
          </button>
        </div>
        {errorMessage !== "" && (
          <Notification message={errorMessage} type="Error"></Notification>
        )}
        <form onSubmit={handleEmailAuth} className="space-y-4">
          {isSignUp && (
            <div>
              <label className="block text-gray-700">Name</label>
              <input
                type="name"
                className="w-full p-2 border border-gray-300 rounded-lg"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
          )}
          <div>
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              className="w-full p-2 border border-gray-300 rounded-lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Password</label>
            <input
              type="password"
              className="w-full p-2 border border-gray-300 rounded-lg"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg"
          >
            {isSignUp ? "Sign Up" : "Sign In"}
          </button>
        </form>
        <button
          onClick={handleGoogleSignIn}
          className="w-full bg-red-500 text-white py-2 rounded-lg mt-4"
        >
          Sign in with Google
        </button>
        <p className="text-center mt-4">
          {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
          <span
            className="text-blue-500 cursor-pointer"
            onClick={() => setIsSignUp(!isSignUp)}
          >
            {isSignUp ? "Sign In" : "Sign Up"}
          </span>
        </p>
      </div>
    </div>
  );
};

export default AuthModal;
