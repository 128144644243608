import { FaPlayCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { PLACEHOLDER_SVG } from "./placeholder-svg";

const NewPlaceholderTrackComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="w-fit mx-auto">
      <p className="text-lg text-center">
        This is your audio library, where your will find your converted PDFs.<br/>
        <Link className="underline underline-offset-1 text-sky-700" to={"/app/upload"}>
          Upload a file to begin
        </Link>, or listen to <span className="bg-gray-200 text-sm p-1 px-2 rounded-xl tracking-tight">welcome.pdf</span>.
      </p>
      <div className="w-fit mx-auto mt-6 relative">
        <svg
          className="absolute w-96 h-96 text-orange-300 -left-14 z-[1]"
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="M44.9,-76.8C58.1,-69.3,68.9,-56.3,77.5,-41.6C86.1,-26.9,92.5,-10.5,91.6,5.5C90.7,21.5,82.5,37.1,71.8,50.3C61.1,63.5,47.8,74.3,32.8,79.9C17.8,85.5,1,85.9,-15.6,83.1C-32.2,80.3,-48.6,74.3,-62.5,63.9C-76.4,53.5,-87.8,38.7,-92.6,22.1C-97.4,5.5,-95.5,-12.9,-89.2,-29.5C-82.9,-46.1,-72.1,-60.9,-57.8,-67.8C-43.5,-74.7,-25.7,-73.7,-8.3,-72.1C9.1,-70.5,31.7,-84.3,44.9,-76.8Z"
            transform="translate(100 100) rotate(45)"
          />
        </svg>
        <div
          className="relative flex flex-col bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer top-10 -left-1 w-64 h-72 overflow-hidden z-10"
          onClick={() =>
            navigate(
              `/app/track/U2FsdGVkX1_JhDhSqs-l4b0mYcprMOPPBtGFpfef-WuOwIN8-B_hJUzwlMttax-9/d91da20b-5db9-4d85-800c-1756f05adaec`
            )
          }
        >
          <div className="relative overflow-hidden z-10">
            <div className="relative object-cover rounded-t-lg truncate">
              <img
                src={PLACEHOLDER_SVG}
                className="w-full h-full"
                alt="Listen to this PDF"
              />
            </div>
            <div
              className="absolute bottom-0 left-0 bg-gradient-to-t from-slate-700 to-transparent w-full p-4 rounded-t-lg"
            >
              <h3 className="text-lg font-semibold text-white">
                <FaPlayCircle className="inline -mt-0.5" /> welcome.pdf
              </h3>
            </div>
          </div>
          <div className="p-4 z-10">
            <p className="text-sm text-gray-500 truncate">
              Get started with ReadPDF.io
            </p>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default NewPlaceholderTrackComponent;
