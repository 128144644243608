import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";

const PrivacyPolicyPage = () => {
  useTitle("Privacy Policy");
  useResetScroll();

  return (
    <div className="container mx-auto mb-10 mt-32 p-6">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-gray-700 mb-6">Last updated on July 26, 2024</p>

      <section>
        <h2 className="text-xl font-semibold mb-2">Summary of Key Points</h2>
        <p className="text-gray-700 mb-4">
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for.
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>What personal information do we process?</li>
          <li>Do we process any sensitive personal information?</li>
          <li>Do we receive any information from third parties?</li>
          <li>How do we process your information?</li>
          <li>
            In what situations and with which parties do we share personal
            information?
          </li>
          <li>How do we keep your information safe?</li>
          <li>What are your rights?</li>
          <li>How do you exercise your rights?</li>
        </ul>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          1. What Information Do We Collect?
        </h2>
        <h3 className="text-lg font-semibold mb-2">
          Personal information you disclose to us
        </h3>
        <p className="text-gray-700 mb-4">
          We collect personal information that you provide to us.
        </p>
        <p className="text-gray-700 mb-4">
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Personal Information Provided by You.</strong> The personal
          information that we collect depends on the context of your
          interactions with us and the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include the following:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Names</li>
          <li>Email addresses</li>
          <li>Usernames</li>
          <li>Passwords</li>
          <li>Contact preferences</li>
          <li>Debit/credit card numbers</li>
          <li>Contact or authentication data</li>
        </ul>
        <p className="text-gray-700 mb-4">
          <strong>Sensitive Information.</strong> We do not process sensitive
          information.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Payment Data.</strong> We may collect data necessary to
          process your payment if you make purchases, such as your payment
          instrument number (such as a credit card number), and the security
          code associated with your payment instrument. All payment data is
          stored by Stripe. You may find their privacy notice link(s) here:{" "}
          <a
            href="https://stripe.com/privacy"
            className="text-blue-600 underline"
          >
            https://stripe.com/privacy
          </a>
          .
        </p>
        <p className="text-gray-700 mb-4">
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>

        <h3 className="text-lg font-semibold mb-2">
          Information automatically collected
        </h3>
        <p className="text-gray-700 mb-4">
          Some information — such as your Internet Protocol (IP) address and/or
          browser and device characteristics — is collected automatically when
          you visit our Services.
        </p>
        <p className="text-gray-700 mb-4">
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p className="text-gray-700 mb-4">
          We also collect information through cookies and similar.
        </p>
        <p className="text-gray-700 mb-4">
          The information we collect includes:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            <strong>Log and Usage Data:</strong> Log and usage data is
            service-related, diagnostic, usage, and performance information our
            servers automatically collect when you access or use our Services
            and which we record in log files. Depending on how you interact with
            us, this log data may include your IP address, device information,
            browser type, and settings and information about your activity in
            the Services (such as the date/time stamps associated with your
            usage, pages and files viewed, searches, and other actions you take
            such as which features you use), device event information (such as
            system activity, error reports (sometimes called "crash dumps"), and
            hardware settings).
          </li>
          <li>
            <strong>Device Data:</strong> We collect device data such as
            information about your computer, phone, tablet, or other device you
            use to access the Services. Depending on the device used, this
            device data may include information such as your IP address (or
            proxy server), device and application identification numbers,
            location, browser type, hardware model, Internet service provider
            and/or mobile carrier, operating system, and system configuration
            information.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          2. How Do We Process Your Information?
        </h2>
        <p className="text-gray-700 mb-4">
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent.
        </p>
        <p className="text-gray-700 mb-4">
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            <strong>
              To facilitate account creation and authentication and otherwise
              manage user accounts:
            </strong>{" "}
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <li>
            <strong>To save or protect an individual's vital interest:</strong>{" "}
            We may process your information when necessary to save or protect an
            individual’s vital interest, such as to prevent harm.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          3. What Legal Bases Do We Rely On To Process Your Information?
        </h2>
        <p className="text-gray-700 mb-4">
          We only process your personal information when we believe it is
          necessary and we have a valid legal reason (i.e., legal basis) to do
          so under applicable law, like with your consent, to comply with laws,
          to provide you with services to enter into or fulfill our contractual
          obligations, to protect your rights, or to fulfill our legitimate
          business interests.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>
            If you are located in the EU or UK, this section applies to you.
          </strong>
        </p>
        <p className="text-gray-700 mb-4">
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            <strong>Consent:</strong> We may process your information if you
            have given us permission (i.e., consent) to use your personal
            information for a specific purpose. You can withdraw your consent at
            any time. Click here to learn more.
          </li>
          <li>
            <strong>Legal Obligations:</strong> We may process your information
            where we believe it is necessary for compliance with our legal
            obligations, such as to cooperate with a law enforcement body or
            regulatory agency, exercise or defend our legal rights, or disclose
            your information as evidence in litigation in which we are involved.
          </li>
          <li>
            <strong>Vital Interests:</strong> We may process your information
            where we believe it is necessary to protect your vital interests or
            the vital interests of a third party, such as situations involving
            potential threats to the safety of any person.
          </li>
        </ul>
        <p className="text-gray-700 mb-4">
          <strong>
            If you are located in Canada, this section applies to you.
          </strong>
        </p>
        <p className="text-gray-700 mb-4">
          We may process your information if you have given us specific
          permission (i.e., express consent) to use your personal information
          for a specific purpose, or in situations where your permission can be
          inferred (i.e., implied consent). You can withdraw your consent at any
          time. Click here to learn more.
        </p>
        <p className="text-gray-700 mb-4">
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way.
          </li>
          <li>For investigations and fraud detection and prevention.</li>
          <li>
            For business transactions provided certain conditions are met.
          </li>
          <li>
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim.
          </li>
          <li>
            For identifying injured, ill, or deceased persons and communicating
            with next of kin.
          </li>
          <li>
            If we have reasonable grounds to believe an individual has been, is,
            or may be victim of financial abuse.
          </li>
          <li>
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province.
          </li>
          <li>
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records.
          </li>
          <li>
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced.
          </li>
          <li>
            If the collection is solely for journalistic, artistic, or literary
            purposes.
          </li>
          <li>
            If the information is publicly available and is specified by the
            regulations.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          4. When And With Whom Do We Share Your Personal Information?
        </h2>
        <p className="text-gray-700 mb-4">
          We may share information in specific situations described in this
          section and/or with the following third parties.
        </p>
        <p className="text-gray-700 mb-4">
          We may need to share your personal information in the following
          situations:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            <strong>Business Transfers:</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          5. Do We Use Cookies And Other Tracking Technologies?
        </h2>
        <p className="text-gray-700 mb-4">
          We may use cookies and other tracking technologies to collect and
          store your information.
        </p>
        <p className="text-gray-700 mb-4">
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          6. Is Your Information Transferred Internationally?
        </h2>
        <p className="text-gray-700 mb-4">
          We may transfer, store, and process your information in countries
          other than your own.
        </p>
        <p className="text-gray-700 mb-4">
          Our servers are located in the European Union. If you are accessing
          our Services from outside the EU, please be aware that your
          information may be transferred to, stored, and processed by us in our
          facilities and by those third parties with whom we may share your
          personal information (see "When And With Whom Do We Share Your
          Personal Information?" above), in and other countries.
        </p>
        <p className="text-gray-700 mb-4">
          If you are a resident of a country not part of the European Economic
          Area (EEA) or United Kingdom (UK), then these countries may not
          necessarily have data protection laws or other similar laws as
          comprehensive as those in your country. However, we will take all
          necessary measures to protect your personal information in accordance
          with this privacy notice and applicable law.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>European Commission's Standard Contractual Clauses:</strong>
        </p>
        <p className="text-gray-700 mb-4">
          We have implemented measures to protect your personal information,
          including by using the European Commission's Standard Contractual
          Clauses for transfers of personal information between our group
          companies and between us and our third-party providers. These clauses
          require all recipients to protect all personal information that they
          process originating from the EEA or UK in accordance with European
          data protection laws and regulations. Our Standard Contractual Clauses
          can be provided upon request. We have implemented similar appropriate
          safeguards with our third-party service providers and partners and
          further details can be provided upon request.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          7. How Long Do We Keep Your Information?
        </h2>
        <p className="text-gray-700 mb-4">
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy notice unless otherwise required by
          law.
        </p>
        <p className="text-gray-700 mb-4">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p className="text-gray-700 mb-4">
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          8. How Do We Keep Your Information Safe?
        </h2>
        <p className="text-gray-700 mb-4">
          We aim to protect your personal information through a system of
          organizational and technical security measures.
        </p>
        <p className="text-gray-700 mb-4">
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          9. Do We Collect Information From Minors?
        </h2>
        <p className="text-gray-700 mb-4">
          We do not knowingly collect data from or market to children under 18
          years of age.
        </p>
        <p className="text-gray-700 mb-4">
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at support@readpdf.io.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          10. What Are Your Privacy Rights?
        </h2>
        <p className="text-gray-700 mb-4">
          In some regions, such as the European Economic Area (EEA), United
          Kingdom (UK), and Canada, you have rights that allow you greater
          access to and control over your personal information. You may review,
          change, or terminate your account at any time.
        </p>
        <p className="text-gray-700 mb-4">
          In some regions (like the EEA, UK, and Canada), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. You can make such a request by contacting us by using the
          contact details provided in the section "How Can You Contact Us About
          This Notice?" below.
        </p>
        <p className="text-gray-700 mb-4">
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p className="text-gray-700 mb-4">
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your local data protection supervisory authority. You can
          find their contact details here:{" "}
          <a
            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            className="text-blue-600 underline"
          >
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
          .
        </p>
        <p className="text-gray-700 mb-4">
          If you are located in Switzerland, the contact details for the data
          protection authorities are available here:{" "}
          <a
            href="https://www.edoeb.admin.ch/edoeb/en/home.html"
            className="text-blue-600 underline"
          >
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </a>
          .
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Withdrawing your consent:</strong> If we are relying on your
          consent to process your personal information, which may be express
          and/or implied consent depending on the applicable law, you have the
          right to withdraw your consent at any time. You can withdraw your
          consent at any time by contacting us by using the contact details
          provided in the section "How Can You Contact Us About This Notice?"
          below or updating your preferences.
        </p>
        <p className="text-gray-700 mb-4">
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal, nor when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>
            Opting out of marketing and promotional communications:
          </strong>{" "}
          You can unsubscribe from our marketing and promotional communications
          at any time by clicking on the unsubscribe link in the emails that we
          send, or by contacting us using the details provided in the section
          "How Can You Contact Us About This Notice?" below. You will then be
          removed from the marketing lists. However, we may still communicate
          with you — for example, to send you service-related messages that are
          necessary for the administration and use of your account, to respond
          to service requests, or for other non-marketing purposes.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Account Information</strong>
        </p>
        <p className="text-gray-700 mb-4">
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Contact us using the contact information provided.</li>
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        <p className="text-gray-700 mb-4">
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Cookies and similar technologies:</strong> Most Web browsers
          are set to accept cookies by default. If you prefer, you can usually
          choose to set your browser to remove cookies and to reject cookies. If
          you choose to remove cookies or reject cookies, this could affect
          certain features or services of our Services. To opt out of
          interest-based advertising by advertisers on our Services visit{" "}
          <a
            href="http://www.aboutads.info/choices/"
            className="text-blue-600 underline"
          >
            http://www.aboutads.info/choices/
          </a>
          .
        </p>
        <p className="text-gray-700 mb-4">
          If you have questions or comments about your privacy rights, you may
          email us at support@readpdf.io.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          11. Controls For Do-Not-Track Features
        </h2>
        <p className="text-gray-700 mb-4">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          12. Do Residents of the European Union Have Specific Privacy Rights?
        </h2>
        <p className="text-gray-700 mb-4">
          Yes, if you are a resident of the European Union, you are granted
          specific rights regarding access to your personal information.
        </p>
        <p className="text-gray-700 mb-4">
          According to the laws of the European Union, European Union residents
          have specific rights regarding their personal information. These
          include:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            The right to request access to the personal information we collect
            from you, change that information, or delete it.
          </li>
          <li>
            The right to be informed about how your personal information is
            being used.
          </li>
          <li>
            The right to restrict the processing of your personal information.
          </li>
          <li>The right to data portability.</li>
          <li>
            The right to object to the processing of your personal information.
          </li>
          <li>
            The right to withdraw consent at any time where we are relying on
            consent to process your personal information.
          </li>
        </ul>
        <p className="text-gray-700 mb-4">
          To exercise these rights, please contact us at support@readpdf.io.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          13. Do We Make Updates To This Notice?
        </h2>
        <p className="text-gray-700 mb-4">
          Yes, we will update this notice as necessary to stay compliant with
          relevant laws.
        </p>
        <p className="text-gray-700 mb-4">
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          14. How Can You Contact Us About This Notice?
        </h2>
        <p className="text-gray-700 mb-4">
          If you have questions or comments about this notice, you may contact
          our Data Protection Officer (DPO) by email at support@readpdf.io.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          15. How Can You Review, Update, Or Delete The Data We Collect From
          You?
        </h2>
        <p className="text-gray-700 mb-4">
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please visit: app.readpdf.io.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;
