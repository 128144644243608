import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/auth.hook";
import { tryGetTrackByUserUidAndFileId } from "../../hooks/database-file";
import { UserTracks } from "../../types";
import { decodeString } from "../../utils/crypto-utils";
import AuthModal from "../../components/auth-modal/auth-modal.component";
import { useRecoilState } from "recoil";
import { showAuthModal } from "../../state/showAuthModal.state";
import { useTitle } from "../../hooks/useTitle.hook";

const SharedLinkPage = () => {
  useTitle();
  const { user } = useAuth();
  const { userId, fileId } = useParams();
  const [showModal, setShowModal] = useRecoilState(showAuthModal);
  const [urlCleanUserId, setUrlCleanUserId] = useState(decodeString(userId));
  const [trackNotFound, setTrackNotFound] = useState(true);
  const [stateTrack, setTrack] = useState<UserTracks>();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return;
    }
    navigate(`/app/track/${userId}/${fileId}`);
  }, [user]);

  useEffect(() => {
    if (userId && fileId) {
      tryGetTrackByUserUidAndFileId(urlCleanUserId, fileId).then(
        (track: UserTracks | null) => {
          if (!track) {
            setTrackNotFound(true);
            return;
          }

          setTrack(track!);
          setTrackNotFound(false);
        }
      );
    }
  }, []);

  const toggleAuthModal = () => {
    setShowModal(!showModal);
  };

  const trackNotFoundMessage = <h2>Track not found.</h2>;

  return (
    <>
      <header className="w-full py-4">
        <div className="container mx-auto text-center">
          <Link to={"/"} className="text-xl font-bold">
            ReadPDF.io reads PDFs for YOU 🫵
          </Link>
        </div>
      </header>

      <main className="flex-grow container mx-auto p-4 text-center">
        {trackNotFound ? (
          trackNotFoundMessage
        ) : (
          <>
            <div className="bg-white rounded-lg shadow-lg p-8 max-w-lg mx-auto">
              <h2 className="text-2xl text-gray-800 mb-6 rounded">
                Hey, someone who cares for you 😍
                <br />
                wants to share a PDF file!
              </h2>
              <div className="bg-orange-100 p-2 italic text-3xl rounded-xl font-light text-orange-800 truncate">
                {stateTrack!.trackUid}
              </div>
              <p className="text-gray-600 text-xl my-6">
                Create your account now and listen to this PDF everywhere, on
                any device - for free!
              </p>
              <button
                onClick={() => setShowModal(true)}
                className="bg-orange-600 text-white py-2 px-4 shadow-md hover:bg-orange-700 transition duration-300 ease-in-out rounded-full"
              >
                Listen to it now!
              </button>
            </div>
          </>
        )}
      </main>
      <AuthModal show={showModal} onClose={toggleAuthModal} />
    </>
  );
};

export default SharedLinkPage;
