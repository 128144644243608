import { MdOutlineCheckCircle } from "react-icons/md";

import { useNavigate } from "react-router-dom";

const ValidateEmailSuccessPage = () => {
  const navigate = useNavigate();

  const proceedToApp = () => {
    navigate("/app/");
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full py-10 text-center mx-auto">
      <MdOutlineCheckCircle size={50} className="mx-auto text-green-500" />
      <h2 className="text-2xl font-bold text-gray-800 mt-4">
        Email Confirmed!
      </h2>
      <p className="text-gray-600 mt-2 text-lg">
        Thank you for verifying your email address! Welcome to the club!
      </p>
      <button
        onClick={proceedToApp}
        className="mt-7 inline-block bg-black text-white py-2 px-4 rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-75"
      >
        Proceed to Dashboard
      </button>
    </div>
  );
};

export default ValidateEmailSuccessPage;
