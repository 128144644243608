import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";

const AuthenticateRoutes = () => {
  const { user, loading } = useCombinedAuthHook();
  const [loadingTimeout, setLoadingTimeout] = useState(true);
  const [loadingFinished, setLoadingFinished] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTimeout(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!loading && !loadingTimeout) {
      setLoadingFinished(true);
    }
  }, [loading, loadingTimeout]);

  if (!loadingFinished) {
    return (
      <div className="h-screen flex items-center justify-center text-center">
        <p className="text-6xl blue-800 font-bold">
          ReadPDF.io
          <br />
          Reads PDFs for YOU 🫵
        </p>
      </div>
    );
  }

  return user ? <Outlet /> : <Navigate to="/" />;
};

export default AuthenticateRoutes;
