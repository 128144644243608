import * as Amplitude from "amplitudejs";
import { useEffect, useState } from "react";
import { FaArrowRight, FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import WaveformPlayerComponent from "../../components/waveform-player/waveform-player.component";
import { useTitle } from "../../hooks/useTitle.hook";
import "./cancel-checkout.styles.scss";

const CancelCheckoutButtonPage = () => {
  const [showDiscount, setShowDiscount] = useState(false);
  const [sessionUrl, setSessionUrl] = useState<string | undefined>(undefined);
  const [previousEmail, setPreviousEmail] = useState<string | undefined>(
    undefined
  );
  useTitle("Listen to your documents, music and papers");

  const navigate = useNavigate();

  configureAmplifyPlayer();

  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get("sid");
    fetch(
      `${process.env.REACT_APP_APPLICATION_URL}/get-session?session_id=${sessionId}`
    )
      .then((response) => response.json())
      .then((session) => {
        setSessionUrl(session.url ?? undefined);
      });
  }, []);

  function configureAmplifyPlayer() {
    Amplitude.init({
      songs: [
        {
          name: "Don't go yet",
          artist: "ReadPDF.io",
          url: "/cancel-2.mp3",
        },
      ],
      waveforms: {
        sample_rate: "100",
      },
    });
  }

  const handleStayButton = () => {
    setShowDiscount(true);
  };

  return (
    <div className="pattern relative h-screen flex justify-center items-center bg-slate-200">
      <div className="container mx-auto px-4 py-12 mt-20">
        <div className="max-w-3xl mx-auto bg-slate-800/85 rounded-lg shadow-2xl shadow-gray-800/40 p-8">
          <h1 className="text-4xl font-bold mb-6 text-center text-slate-100">
            Hey, we wanted to let you know something.
          </h1>
          <div className="mb-8 w-72 mx-auto hover:shadow-xl hover:shadow-slate-600/20">
            <WaveformPlayerComponent theme="dark" />
          </div>
          {/* <p className="text-xl mb-8 text-center text-slate-300">
            
          </p> */}
          <ul className="space-y-4 mb-8">
            {[
              "Realistic voices",
              "Multiple languages",
              "Image recognition and reading",
              "Money-back guarantee",
            ].map((benefit, index) => (
              <li key={index} className="flex items-center text-slate-300">
                <FaCheck className="w-6 h-6 mr-2 text-green-400" />
                {benefit}
              </li>
            ))}
          </ul>
          {!showDiscount ? (
            <div className="flex justify-center space-x-4">
              {/* <button
                onClick={handleStayButton}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
              > */}
              <a
                href={sessionUrl ?? "/"}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
              >
                Stay and Complete Order{" "}
                <FaArrowRight className="ml-2 w-5 h-5" />
                {/* </button> */}
              </a>
              <a
                href="/"
                className="bg-slate-600 hover:bg-slate-500 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out"
              >
                No, I want to leave
              </a>
            </div>
          ) : (
            <div className="text-center">
              <p className="text-2xl font-bold mb-4 text-green-400">
                You've unlocked a special 10% discount!
              </p>
              <p className="text-xl mb-6 text-slate-300">
                Use code{" "}
                <span className="font-bold text-yellow-400">STAYANDSAVE</span>{" "}
                at checkout.
              </p>
              <a
                href={sessionUrl ?? "/"}
                className={`block w-full py-3 px-6 border border-transparent rounded-full text-center font-medium text-white bg-green-500 hover:bg-green-600 transition duration-300 ease-in-out transform hover:scale-105`}
              >
                Complete Order
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CancelCheckoutButtonPage;
