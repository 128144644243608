import { useEffect } from "react";

export const useTitle = (title?: string) => {
  useEffect(() => {
    if (title) document.title = `${title} - ReadPDF.io | Listen to your PDFs`;
    else document.title = `ReadPDF.io | Listen to your PDFs`;

    return () => {
      document.title = "ReadPDF.io | Listen to your PDFs";
    };
  }, []);
};
