import { useEffect, useState } from 'react';
import { StripeSessionStatus } from '../types';
import { useDatabaseUser } from './database-user.hook';

const useStripeSession = () => {
    const [status, setStatus] = useState('');
    const [sessionId, setSessionId] = useState('');
    const { updateUser } = useDatabaseUser();

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const urlSessionId = urlParams.get('session_id');

        if (!urlSessionId) {
            return;
        }

        setSessionId(urlSessionId);

        fetch(`${process.env.REACT_APP_APPLICATION_URL}/session-status?session_id=${urlSessionId}`)
            .then((res) => res.json())
            .then((data: StripeSessionStatus) => {
                setStatus(data.status);
            });
    }, [updateUser]);

    return { status, sessionId };
};

export default useStripeSession;
