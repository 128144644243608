import { atom } from "recoil";

export const showAuthModal = atom({
    key: 'showAuthModal',
    default: false,
});

export const navigateToAuthModalProp = atom({
    key: 'navigateToAuthModalProp',
    default: '',
});