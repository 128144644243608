import { useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";


interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "Can I convert any PDF to audio?",
    answer:
      "Indeed, you can convert any PDF into audio with ReadPDF.io. As long as it belongs to you and you have the permissions to manage it, you are free to upload and listen to it.",
  },
  {
    question: "What formats are supported?",
    answer:
      "ReadPDF.io currently only supports listening to PDFs.",
  },
  {
    question: "Is my data secure?",
    answer:
      "Yes, your data is securely stored, employing the latest security practices while uploading and during storing.",
  },
  {
    question: "Can I use ReadPDF.io for free?",
    answer:
      "You can indeed use ReadPDF.io for free. However, the free version is only offering limited listening, no sharing, nor image description.",
  },
  {
    question: "A friend shared a ReadPDF.io link with me. Can I listen it for free?",
    answer:
      "You can listen the document your friend shared with you for free. If you want to upload your document, you might need to consider one of the tiers we offer.",
  },
];

const FaqItem = ({ item }: { item: FAQItem }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        className="w-full text-left p-4 bg-white rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200 ease-in-out"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <div className="flex justify-between items-center">
          <span className="font-semibold text-lg">{item.question}</span>
          {isOpen ? (
            <MdExpandLess className="w-5 h-5 text-gray-500" />
          ) : (
            <MdExpandMore className="w-5 h-5 text-gray-500" />
          )}
        </div>
      </button>
      {isOpen && (
        <div className="mt-2 p-4 bg-gray-50 rounded-lg">
          <p>{item.answer}</p>
        </div>
      )}
    </div>
  );
};

export default function FAQSection() {
  return (
    <section className="w-11/12 mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="space-y-6">
        {faqData.map((item, index) => (
          <FaqItem key={index} item={item} />
        ))}
      </div>
    </section>
  );
}
