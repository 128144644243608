import { FaFacebook, FaInstagram, FaUserCircle } from "react-icons/fa";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { authState } from "../../state/auth.state";
import {
  navigateToAuthModalProp,
  showAuthModal,
} from "../../state/showAuthModal.state";
import AuthModal from "../auth-modal/auth-modal.component";
import "./layout.styles.scss";
import UnauthenticatedNavbar from "../unauthenticated-navbar/unauthenticated-navbar.component";

const Layout = () => {
  const [showModal, setShowModal] = useRecoilState(showAuthModal);
  const navigateToGlobalProp = useRecoilValue(navigateToAuthModalProp);
  const navigate = useNavigate();

  useRecoilState(authState);

  const toggleAuthModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <UnauthenticatedNavbar />

      <main className="mx-auto pt-0">
        <Outlet />
      </main>

      <footer className="bg-gray-800 text-white py-12">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-6 md:mb-3">
              <a href="/" className="text-2xl font-bold text-white">
                ReadPDF.io
              </a>
            </div>
            <div className="flex space-x-6 mb-6 md:mb-5">
              <a href="/" className="hover:text-blue-500">
                Home
              </a>
              <Link to={"/privacy-policy"} className="hover:text-blue-500">
                Privacy Policy
              </Link>
              <Link
                to={"/terms-and-conditions"}
                className="hover:text-blue-500"
              >
                Terms and Conditions
              </Link>
            </div>
            <div className="flex space-x-6">
              <a
                href="https://www.facebook.com/profile.php?id=61563878062519"
                target="_blank"
                className="hover:text-blue-500"
              >
                <FaFacebook size={24} />
              </a>
              <a
                href="https://www.instagram.com/listentopdfs/"
                target="_blank"
                className="hover:text-blue-500"
              >
                <FaInstagram size={24} />
              </a>
            </div>
          </div>
          <div className="mt-8 text-center text-gray-400">
            <p>
              &copy; {new Date().getFullYear()} ReadPDF.io. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
      <AuthModal
        show={showModal}
        onClose={toggleAuthModal}
        navigateTo={navigateToGlobalProp}
      />
    </>
  );
};

export default Layout;
