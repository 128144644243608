import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SubscribePage = () => {
  const [stripePromise, setStripePromise] = useState<Promise<any>>();
  const navigate = useNavigate();


  useEffect(() => {
    if (!stripePromise) {
      setStripePromise(loadStripe('pk_test_51PbTbxFJ3gpTYLFuLljFrY70PXRtNhwrxkqvGF6c1bJJGujI14bQHPi62mKstzUPrakda1ojqp9LP1IXg8CI5Vs900isIV6Unj'));
    }
  }, []);

  const fetchClientSecret = useCallback(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const priceTagUrl = urlParams.get('p');

    return fetch(`${process.env.REACT_APP_APPLICATION_URL}/create-checkout-session`, {
      method: "POST",
      body: JSON.stringify({
        priceTag: priceTagUrl
      })
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        }
        return res.json();
      })
      .catch((_) => navigate('/app/pricing'))
      .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      {stripePromise && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
};

export default SubscribePage;
