import React, { ReactNode } from "react";
import { GiCheckMark } from "react-icons/gi";

type PricingFeaturesListComponentProps = {
  children: ReactNode;
};

type PricingFeatureComponentProps = {
  children: string | JSX.Element;
};

const PricingFeaturesListComponent = ({
  children,
}: PricingFeaturesListComponentProps) => {
  return (
    <ul className="mt-6 space-y-4">
      {React.Children.map(children, (child) => {
        // Ensure child is a React element with valid props
        if (React.isValidElement(child)) {
          return <li className="flex items-start">{child}</li>;
        }
        return null;
      })}
    </ul>
  );
};

const PricingFeatureComponent = ({
  children,
}: PricingFeatureComponentProps) => {
  return (
    <>
      <div className="flex-shrink-0">
        <GiCheckMark className="mt-1" color="green" />
      </div>
      <p className="ml-3 text-base text-gray-700">{children}</p>
    </>
  );
};

export default PricingFeaturesListComponent;
export { PricingFeatureComponent };
