import { Route, Routes } from "react-router-dom";
import AuthenticateRoutes from "./components/authenticate-routes/authenticate-routes";
import AuthenticatedLayout from "./components/authenticated-layout/authenticated-layout.component";
import CustomToaster from "./components/custom-toaster/custom-toaster.component";
import Layout from "./components/layout/layout.component";
import useAmplitudeAnalytics from "./hooks/amplitude-analytics.hook";
import { useCombinedAuthHook } from "./hooks/combined-auth.hook";
import AuthenticatePage from "./pages/authenticate/authenticate.page";
import CancelCheckoutButtonPage from "./pages/cancel-checkout/cancel-checkout.page";
import DashboardPage from "./pages/dashboard/dashboard.page";
import HomePage from "./pages/home/home.page";
import PricingTablePage from "./pages/pricing-table/pricing-table.page";
import PrivacyPolicyPage from "./pages/privacy-policy/privacy-policy.page";
import SettingsPage from "./pages/settings/settings.page";
import SharedLinkPage from "./pages/shared-link/shared-link.page";
import ReturnPage from "./pages/subscribe/return.page";
import SubscribePage from "./pages/subscribe/subscribe.page";
import TermsAndConditionsPage from "./pages/terms-and-conditions/terms-and-conditions.page";
import TrackPage from "./pages/track/track.page";
import UploadPage from "./pages/upload/upload.page";
import ValidateEmailSuccessPage from "./pages/validate-email-success/validate-email-success.page";
import ValidateEmailPage from "./pages/validate-email/validate-email.page";
import SuccessCheckoutPage from "./pages/success-checkout/success-checkout.page";

function App() {
  useCombinedAuthHook();
  useAmplitudeAnalytics();

  return (
    <div className="App">
      <CustomToaster />
      <Routes>
        <Route path="/track/:userId/:fileId" element={<SharedLinkPage />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/flow/cancel" element={<CancelCheckoutButtonPage />} />
          <Route path="/flow/success" element={<SuccessCheckoutPage />} />
          <Route
            path="terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="authenticate" element={<AuthenticatePage />} />
        </Route>
        <Route path="/app" element={<AuthenticateRoutes />}>
          <Route element={<AuthenticatedLayout />}>
            <Route index element={<DashboardPage />} />
            <Route path="subscribe" element={<SubscribePage />} />
            <Route path="return" element={<ReturnPage />} />
            <Route path="pricing" element={<PricingTablePage />} />
            <Route path="upload" element={<UploadPage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="track/:userId/:fileId" element={<TrackPage />} />
            <Route path="validate-email" element={<ValidateEmailPage />} />
            <Route
              path="validate-email/act"
              element={<ValidateEmailSuccessPage />}
            />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
