import { useEffect, useState } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { showAuthModal } from "../../state/showAuthModal.state";
import { showFileConvertedModal } from "../../state/showFileConverted.state";
import { verifiedEmailState } from "../../state/verified-email.state";
import { ActiveSubscriptionTypes } from "../../types";
import AuthModal from "../auth-modal/auth-modal.component";
import UploadFinishedModal from "../upload-finished-modal/upload-finished-modal.component";
import UploadProgress from "../upload-progress/upload-progress.component";
import { MdWorkspacePremium } from "react-icons/md";

import "./authenticated-layout.scss";

const AuthenticatedLayout = () => {
  const { user, databaseUser, loading, logout, subscriptionStatus } =
    useCombinedAuthHook();
  const [showModal, setShowModal] = useRecoilState(showAuthModal);
  const [showFileConvertedModalState, setShowFileConvertedModalState] =
    useRecoilState(showFileConvertedModal);
  const emailVerified = useRecoilValue(verifiedEmailState);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(true);
  const [showSubscribePrompt, setShowSubscribePrompt] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasActiveSubscription) {
      setShowSubscribePrompt(true);
    } else {
      setShowSubscribePrompt(false);
    }
  }, [hasActiveSubscription]);

  useEffect(() => {
    if (loading) return;
    setHasActiveSubscription(
      ActiveSubscriptionTypes.includes(subscriptionStatus)
    );
  }, [subscriptionStatus, loading]);

  // If ever using the AI chatbot again, comment this out
  // useAiChatbot();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        !event.target.closest("#dropdown-user") &&
        !event.target.closest('[data-dropdown-toggle="dropdown-user"]')
      ) {
        setDropdownOpen(false);
      }
      if (
        !event.target.closest("#logo-sidebar") &&
        !event.target.closest('[aria-controls="logo-sidebar"]')
      ) {
        setSidenavOpen(false);
      }
    };

    const handleScroll = () => {
      setDropdownOpen(false);
      setSidenavOpen(false);
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setDropdownOpen(false);
    setSidenavOpen(false);
  }, [location]);

  useEffect(() => {
    if (emailVerified) return;

    navigate("/app/validate-email");
  }, [navigate, emailVerified]);

  const toggleAuthModal = () => {
    setShowModal(!showModal);
  };

  const getInitials = (name?: string) => {
    return name?.charAt(0) ?? "R";
  };

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                onClick={() => setSidenavOpen(!sidenavOpen)}
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a href="#" className="flex ms-2 md:me-24">
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  ReadPDF.io
                </span>
              </a>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ms-3">
                <div>
                  <button
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    aria-expanded="false"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    data-dropdown-toggle="dropdown-user"
                  >
                    <span className="sr-only">Open user menu</span>
                    {user?.photo !== null ? (
                      <img
                        className="w-8 h-8 rounded-full"
                        referrerPolicy="no-referrer"
                        src={user.photo}
                        alt="Logo"
                      />
                    ) : (
                      <div className="w-8 h-8 rounded-full bg-slate-700 text-white text-center flex justify-center align-middle">
                        <span className="self-center">
                          {getInitials(user?.name ?? "R")}
                        </span>
                      </div>
                    )}
                  </button>
                </div>
                <div
                  className={`absolute right-2 top-9 z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 ${
                    !dropdownOpen ? "hidden" : ""
                  }`}
                  id="dropdown-user"
                >
                  <div className="px-4 py-3" role="none">
                    <p
                      className="text-sm text-gray-900 dark:text-white"
                      role="none"
                    >
                      {databaseUser?.name ?? ""}
                    </p>
                    <p
                      className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                      role="none"
                    >
                      {databaseUser?.email ?? ""}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    {ActiveSubscriptionTypes.includes(subscriptionStatus) &&
                      databaseUser.stripeSessionId && (
                        <li>
                          <form
                            className="block"
                            method="POST"
                            action={`${process.env.REACT_APP_APPLICATION_URL}/create-portal-session`}
                          >
                            <input
                              type="hidden"
                              name="lookup_key"
                              value={databaseUser.stripeSessionId}
                            />
                            <button
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                              type="submit"
                            >
                              Subscription
                            </button>
                          </form>
                        </li>
                      )}
                    <li>
                      <Link
                        to={"/app/settings"}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem"
                      >
                        Settings
                      </Link>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem"
                        onClick={() => logout()}
                      >
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 ${
          sidenavOpen ? "-translate-x-0" : "-translate-x-full"
        } sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to="/app"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 18"
                >
                  <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">
                  My Library
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/app/upload"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13V1m0 0L1 5m4-4 4 4"
                  ></path>
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Upload PDF
                </span>
              </Link>
            </li>
            <hr />
            <li>
              <a
                href="https://readpdfio.canny.io/feature-requests"
                target="_blank"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 3.464V1.1m0 2.365a5.338 5.338 0 0 1 5.133 5.368v1.8c0 2.386 1.867 2.982 1.867 4.175C17 15.4 17 16 16.462 16H3.538C3 16 3 15.4 3 14.807c0-1.193 1.867-1.789 1.867-4.175v-1.8A5.338 5.338 0 0 1 10 3.464ZM4 3 3 2M2 7H1m15-4 1-1m1 5h1M6.54 16a3.48 3.48 0 0 0 6.92 0H6.54Z"
                  ></path>
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Request a Feature
                </span>
              </a>
            </li>
            {showSubscribePrompt && (
              <li className="relative group cursor-pointer">
                <div
                  className="absolute -inset-[0.07rem] bg-gradient-to-r from-cyan-500 via-purple-500 to-red-500 rounded-lg 
               group-hover:opacity-100 transition duration-500 group-hover:duration-200 animate-gradient"
                ></div>
                <Link
                  to={"/app/pricing"}
                  className="relative flex items-center py-3 ring-1 ring-gray-900/5 rounded-lg leading-none justify-start p-2 
               bg-white/80 text-gray-900 dark:text-white hover:bg-white/70 dark:hover:bg-gray-700 group"
                  role="menuitem"
                >
                  <MdWorkspacePremium
                    className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 
                                         dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  />
                  <span className="flex-1 ms-3 whitespace-nowrap">
                    Go Premium
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </aside>

      <div className="p-4 sm:ml-64">
        <div className="p-4 border-gray-200 rounded-lg dark:border-gray-700 mt-14 mb-6 min-h-screen h-fit relative">
          <Outlet />
        </div>
        <footer className="bg-gray-800 text-white py-12 rounded-xl">
          <div className="container mx-auto px-6">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="mb-6 md:mb-3">
                <a href="/" className="text-2xl font-bold text-white">
                  ReadPDF.io
                </a>
              </div>
              <div className="flex space-x-6 mb-6 md:mb-5">
                <a href="/" className="hover:text-blue-500">
                  Home
                </a>
                <Link to={"/privacy-policy"} className="hover:text-blue-500">
                  Privacy Policy
                </Link>
                <Link
                  to={"/terms-and-conditions"}
                  className="hover:text-blue-500"
                >
                  Terms and Conditions
                </Link>
              </div>
              <div className="flex space-x-6">
                <a
                  href="https://www.facebook.com/profile.php?id=61563878062519"
                  target="_blank"
                  className="hover:text-blue-500"
                >
                  <FaFacebook size={24} />
                </a>
                <a
                  href="https://www.instagram.com/listentopdfs/"
                  target="_blank"
                  className="hover:text-blue-500"
                >
                  <FaInstagram size={24} />
                </a>
              </div>
            </div>
            <div className="mt-8 text-center text-gray-400">
              <p>
                &copy; {new Date().getFullYear()} ReadPDF.io. All rights
                reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>

      {!!databaseUser && <UploadProgress />}
      <AuthModal show={showModal} onClose={toggleAuthModal} />
      <UploadFinishedModal
        show={showFileConvertedModalState}
        onClose={() => {
          setShowFileConvertedModalState(false);
        }}
      />
    </>
  );
};

export default AuthenticatedLayout;
