import { useEffect, useState } from "react";
import { AvailableLanguage } from "../../types";

const languages: AvailableLanguage[] = [
  {
    name: "English",
    country: "United States",
    code: "en",
    flag: "us",
    suggested: true,
  },
  {
    name: "Spanish",
    country: "Spain",
    code: "es",
    flag: "es",
    suggested: true,
  },
  {
    name: "German",
    country: "Germany",
    code: "de",
    flag: "de",
    suggested: true,
  },
  {
    name: "French",
    country: "Canada",
    code: "fr",
    flag: "ca",
    suggested: false,
  },
  {
    name: "Portuguese",
    country: "Brazil",
    code: "pt",
    flag: "br",
    suggested: false,
  },
  {
    name: "Dutch",
    country: "Netherlands",
    code: "nl",
    flag: "nl",
    suggested: false,
  },
  {
    name: "Romanian",
    country: "Romania",
    code: "ro",
    flag: "ro",
    suggested: false,
  },
];

type LanguagePickerProps = {
  selectedLanguage: string;
  changeSelectedLanguage: (lang: string) => void;
};

const LanguagePicker = ({
  selectedLanguage,
  changeSelectedLanguage,
}: LanguagePickerProps) => {
  const [language, setLanguage] = useState("en");
  const [error, setError] = useState(false);

  useEffect(() => {
    setLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleChange = (e: any) => {
    setLanguage(e.target.value);
    changeSelectedLanguage(e.target.value);
    setError(false);
  };

  return (
    <>
      <select
        className={`block w-full bg-white ring-1 border-none hover:bg-slate-200 focus:bg-white focus:ring-4 focus:outline-none focus:ring-slate-400 ${
          error ? "ring-red-500" : "ring-slate-400"
        } px-4 py-2 pr-8 rounded-lg shadow leading-tight cursor-pointer`}
        value={language}
        onChange={handleChange}
      >
        {/* <option value="" disabled>
          Select a language to convert PDF
        </option> */}
        {/* <optgroup> */}
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {`${lang.name}`}
          </option>
        ))}
        {/* </optgroup> */}
      </select>
      {error && (
        <p className="text-red-500 text-xs italic mt-2">
          Please select a language.
        </p>
      )}
    </>
  );
};

export default LanguagePicker;
